/**
 *  google map
 */

(function ($) {

    "use strict";

    var map = $('.mapBox__map'),
        mapGoogle = null,
        centerPosition = null,
        coordsX = 55.766093,
        coordsY = 37.582165;

    // init map

    function init() {

        var mapOptions = {
            zoom: 17,
            zoomControl: !0,
            scrollwheel: !1,
            center: new google.maps.LatLng(coordsX, coordsY)
        };

        mapGoogle = new google.maps.Map(document.getElementById('googleMap'), mapOptions);

        var iconBase = 'img/';

        var gmapMarker = new google.maps.Marker({
            position: new google.maps.LatLng(coordsX, coordsY),
            map: mapGoogle,
            icon: iconBase + 'mapPin.png',
            animation: google.maps.Animation.DROP
        });

        centerPosition = mapGoogle.getCenter();

    }

    // make direction from undeground

    function drawDirection() {

        var directionsDisplay = new google.maps.DirectionsRenderer(),
            directionsService = new google.maps.DirectionsService();

        var request = {
            origin: new google.maps.LatLng(55.760784, 37.581270), //точка старта
            destination: new google.maps.LatLng(coordsX, coordsY), //точка финиша
            travelMode: google.maps.TravelMode.WALKING //режим прокладки маршрута
        };

        directionsService.route(request, function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
            }
        });

        directionsDisplay.setMap(mapGoogle);

    }

    // start

    if (map.length) {

        if (google && google.maps) {

            google.maps.event.addDomListener(window, 'load', init);

            $('.mapBox__button').on('click', function (e) {
                console.log('drow');
                e.preventDefault();
                drawDirection();
            });

            $(window).resize(function () {
                if (typeof google !== 'undefined') {
                    google.maps.event.trigger(mapGoogle, "resize");
                    mapGoogle.setCenter(centerPosition);
                }
            });

        }
    }

})(jQuery);