

/**
 *  модальные окна . простенький интерфейс
 *
 *  TODO -- добавить передачу триггера в before after
 *  TODO встроить bg
 *  TODO ajax загрузка по урл
 *  TODO простой оверлей с индикатором .
 *
 */


(function ($) {

    "use strict";

    /**
     * базовые опции
     * @type {{handlerClass: string, background: string}}
     */

    var defaults = {
        'background': 'rgba(255,255,255, 0.7)',
        'fadeSpeed': 200,
        'useBlur': true,
        'blurElementClassName': 'mainContentWrapper',
        'blurClassName': 'blurFiler',
        'titleClassName': 'mainOverlay__headerInner',
        'contentClassName': 'mainOverlay__content',
        'closeButtonClassName': 'closeMenuButton',
        'resetFormsBeforeOpen': true,
        'target': '#dynamic',
        'beforeInit': function () {},
        'beforeClose' : function () {}
    };

    /**
     * private API
     * @type {{}}
     * @private
     */

    var privateData = {
        _opened: null
    };

    /**
     *
     * @param params
     * @returns {OverlayObj}
     * @constructor
     */

    var OverlayObj = function (params) {

        this.options = $.extend({}, defaults, params);

        if (!(this instanceof OverlayObj)) {
            return new OverlayObj(params);
        }

        if (this.options.title) {
            this.setTitle(this.options.title);
        }

        if (this.options.content) {
            this.setContent(this.options.content);
        }

        if (this.options.target) {
            this.setTarget(this.options.target);
        }

        if (typeof this.options.beforeInit === 'function') {
            this.options.beforeInit.call(this, this.options.target);
        }

        this.display();

    };

    OverlayObj.prototype = {

        constructor: 'OverlayObj',

        /**
         *
         */

        display: function () {

            if (privateData._opened !== null) {
                return this;
            }

            if (this.options.target) {

                if (this.options.resetFormsBeforeOpen === true) {
                    this.resetForms();
                }

                this.useBlurFilter();

                var that = this;
                var closeButton = $('.' + this.options.closeButtonClassName, this.options.target);

                $(this.options.target).fadeIn(this.options.fadeSpeed, function () {

                    if (closeButton.length) {

                        closeButton.bind('click.modalWindow', function (event) {
                            event.preventDefault();
                            that.close();
                        });
                    }

                });

            }

        },

        /**
         *
         */

        resetForms: function () {
            $(this.options.target).find('input').filter(':text[value != ""]').val('');
        },


        /**
         *
         */

        clearAllContent: function () {
            this.setContent('');
            this.setTitle('');
        },

        /**
         *
         * @returns {OverlayObj}
         */

        useBlurFilter: function (close) {

            var bluredEl = $('.' + this.options.blurElementClassName);

            if (this.options.useBlur && bluredEl.length) {

                if (typeof close !== 'undefined') {
                    bluredEl.removeClass(this.options.blurClassName);
                    return this;
                }

                bluredEl.addClass(this.options.blurClassName);

            }

            return this;

        },

        /**
         *
         */

        close: function () {

            if (privateData._opened !== null) {

                if (typeof this.options.beforeClose === 'function') {
                    this.options.beforeClose.call(this, this.options.target);
                }

                this.useBlurFilter(true);
                $(this.options.target).fadeOut(this.options.fadeSpeed);

                privateData._opened = null;
            }

            return false;
        },

        /**
         *
         * @param target
         */

        setTarget: function (target) {

            var targetBox = $(target);

            if (targetBox.length) {
                this.options.target = targetBox;
            }

            return this;

        },

        /**
         *
         * @param html
         */

        setContent: function (html) {

            this.content = html;

            $('.' + this.options.contentClassName, this.options.target).html(this.content);

            return this;
        },

        /**
         *
         * @returns {*}
         */


        getContent: function () {
            return this.content;
        },

        /**
         *
         * @param title
         */

        setTitle: function (title) {

            this.title = title;
            $('.' + this.options.titleClassName, this.options.target).html(this.title);

            return this;
        },

        /**
         *
         * @returns {*}
         */

        getTitle: function () {
            return this.title;
        }

    };


    /**
     * public API
     * @type {{init: init, show: show, hide: hide}}
     */

    var methods = {

        init: function (params) {

            return this.each(function (index, item) {

                $(item).bind('click.modalWindow', function (event) {

                    event.preventDefault();

                    var itemObj = $(this);

                    methods.show.call(this, $.extend({}, defaults, params, {
                        title: itemObj.attr('data-title') || null,
                        content: itemObj.attr('data-content') || null,
                        target: itemObj.attr('data-target')
                    }));

                });

            });

        },

        show: function (params) {

            if (privateData._opened !== null) {
                return this;
            }

            privateData._opened = new OverlayObj(params);

            return privateData._opened;
        },

        close: function () {
            privateData._opened.close();
            return this;
        },

        getInstance: function () {
            return privateData._opened || false;
        }

    };

    /**
     * @param method -- метод public API
     * @returns {*}
     */

    $.fn.modalWindow = function (method) {

        if (methods[method]) {

            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));

        } else if (typeof method === 'object' || !method) {

            return methods.init.apply(this, arguments);

        } else {

            $.error('Метод с именем ' + method + ' не существует');

        }

        return this;

    };

    $.modalWindow = $.fn.modalWindow;

})(jQuery);