/**
 * entry point
 */

(function ($) {

    $(function () {

        var topSlider = new Swiper('.swiper-container', {
            effect: 'fade',
            speed: 1200,
            loop: true,
            spaceBetween: 0,
            slidesPerView: 1,
            slidesPerGroup: 1,
            // pagination: '.swiper-pagination',
            // paginationClickable: true
        });

    });


})(jQuery);